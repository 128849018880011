
$(document).ready(function(){
$('#mobil-nav-toggle').on('click', function(){
         $(this).toggleClass('on');
         $('#mobil-nav').toggleClass('nodisplay')
      })
$('.mobil-nav__list').on('mouseleave', function(){
   $('#mobil-nav-toggle').toggleClass('on');
   $('#mobil-nav').toggleClass('nodisplay');
})
})